<template>
  <div class="container pt-3 d-md-none">
    <a href="/checkout/supplyOrder/cart" class="btn btn-outline-light d-block w-100">
      <span class="lnr ic ic-cart-sell"></span>
      Ankauf
      <div class="badge" v-cloak v-if="cart.supplyOrder.count">{{ cart.supplyOrder.count }}</div>
    </a>
    <a href="/checkout/order/cart" class="btn btn-outline-light d-block w-100">
      <span class="lnr ic ic-cart-buy"></span>
      Verkauf
      <div class="badge" v-cloak v-if="cart.order.count">{{ cart.order.count }}</div>
    </a>
    <a
      class="btn btn-outline-light d-block w-100 account js-account-button mb-4"
      @click="usernameClicked"
      href="/account/settings"
    >
      <span class="lnr lnr-lock" :class="{ 'lnr-user' : loggedIn }"></span>
      <span v-text="loggedIn ? 'Konto' : 'Login'"></span>
    </a>

    <select-counter mobile />
  </div>
</template>

<script>
import $ from 'jquery'

export default {
  computed: {
    cart () { return this.$store.state.cart },
    user () { return this.$store.state.user },
    loggedIn () {
      return this.user && this.user.isLoggedIn
    }
  },
  methods: {
    usernameClicked (e) {
      if (this.user.username) return; // Follow the Link as usual
      e.preventDefault()
      $.fancybox.open({ src: '#account' })
    }
  }
}
</script>


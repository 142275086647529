import get from "lodash/get";
import $ from "jquery";
import { computeLastFilterChanged } from "./products";
import { getSearchParams  } from "../../utils";

// products-list.js - used for Product List Block

// For computeLastFilterChanged
let oldFilter = {};

export default (container, createVue) => {
  const items = container
    .find(".table--articles tbody tr")
    .toArray()
    .map((el) => $(el).data("filterproperties"));
//console.log('items (list)', items);


  const search = getSearchParams();
  const propertyNames = Object.keys(items[0] || {});
  let filters = {};
  propertyNames.forEach((name) => {
    filters[name] = (search[name] || "").trim();
  });

  const baseLocation =
    window.location.href.replace(window.location.search, "") + "?";

  createVue({
    name: "Produktfilter",
    data: {
      items,
      filters,
    },
    computed: {
      subsidiaryId() {
        return get(this.$store, "state.subsidiaries.selected.id");
      },
      filterProperties() {
        let filterProperties = {};
        propertyNames.forEach((name) => (filterProperties[name] = {}));
        items.forEach((item) => {
          propertyNames.forEach((propertyName) => {
            if (
              propertyNames.every(
                (name) =>
                  name == propertyName ||
                  !this.filters[name] ||
                  item[name] == this.filters[name]
              )
            ) {
              if (item[propertyName])
                filterProperties[propertyName][
                  "_" + item[propertyName].replace(/-/g, "_")
                ] = true;
            }
          });
        });
        return filterProperties;
      },
      visible() {
        let visible = {};
        items.forEach((item, i) => {
          visible["_" + i] = propertyNames.every(
            (name) => !this.filters[name] || item[name] == this.filters[name]
          );
        });
        return visible;
      },
    },
    updated() {
      $(".select-picker").selectpicker("refresh");
    },
    watch: {
      filters: {
        handler(newFilters) {
          const lastFilterChanged = computeLastFilterChanged(newFilters, oldFilter);
          if (!newFilters.country && lastFilterChanged.includes('country')) {
            newFilters.serie = "";
          }

          // Update URL
          let search = [];
          for (let key in newFilters) {
            if (newFilters[key]) {
              search.push(key + "=" + filters[key]);
            }
          }
          let url = baseLocation + search.join("&");
          if (url != window.location.href) {
            history.replaceState(null, null, url);
          }
        },
        deep: true,
      },
    },
  });
};

<template>
  <DynamicScroller class="table--articles" :items="items" :min-item-size="132" page-mode key-field="permalink" emitUpdate @update="(startIndex, endIndex) => $emit('update', startIndex, endIndex)">
    <template v-slot="{ item, index, active }">
      <DynamicScrollerItem :item="item" :active="active" :data-index="index">
        <div class="table--articles__row" :class="{ 'bg-medium': index % 2 }" v-if="item.label">
          <div class="table--articles__image">
            <div class="img-square">
              <a :href="item.permalink">
                <img :src="item.imageUrl" class="img-fluid" />
              </a>
            </div>
          </div>
          <div class="table--articles__name">
            <a :href="item.permalink">
              <b class="d-block text-body">
                {{ item.label }}
                <span v-if="item.year">({{ item.year }})</span>
              </b>
              <b class="d-block text-muted">{{ item.serie.label }}</b>
            </a>
          </div>
          <div class="table--articles__value" v-show="item.fineWeight !== '0 g'">
            <b>{{ item.fineWeight }}</b>
            <span>Feingewicht</span>
          </div>
          <div class="table--articles__price table--articles__price--sell">
            <b v-if="item.priceInfo.pricePurchase && item.canSell"
              >{{ item.priceInfo.pricePurchase | toCurrency }}&nbsp;€</b
            >
            <b v-else>n.&nbsp;verf</b>
            <a :href="item.permalink" class="text-link">verkaufen</a>
          </div>
          <div class="table--articles__price table--articles__price--buy">
            <mp-availability-info
              :availability="item.availabilityByOrderTypeID"
              v-slot="{ availability }"
            >
              <b
                v-if="availability.anywhere === 'none'"
                title="nicht verfügbar"
                v-cloak
                >n.&nbsp;verf.</b
              >
              <b v-else>{{ item.priceInfo.priceGross | toCurrency }}&nbsp;€</b>
            </mp-availability-info>
            <a :href="item.permalink" class="text-link">kaufen</a>
          </div>
          <div class="table--articles__status" v-cloak>
            <mp-availability-info
              :availability="item.availabilityByOrderTypeID"
              v-slot="{ availability }"
            >
              <div class="d-flex flex-row">
                <span
                  class="badge"
                  :class="availability.online"
                  title="Verfügbarkeit online"
                >
                  <span
                    class="lnr"
                    :class="{
                      'lnr-checkmark-circle': availability.online !== 'none',
                      'lnr-cross-circle': availability.online === 'none'
                    }"
                  ></span>
                  <!-- lnr-checkmark-circle -->
                </span>
                <span
                  class="badge ml-2"
                  :class="availability.local"
                  v-if="subsidiaries.selected"
                  title="Verfügbarkeit in Ihrer Filiale"
                >
                  <span class="lnr lnr-map-marker"></span>
                  <!-- lnr-checkmark-circle -->
                </span>
              </div>
            </mp-availability-info>
          </div>
        </div>
      </DynamicScrollerItem>
    </template>
  </DynamicScroller>
</template>

<script>
import get from 'lodash/get'

export default {
  props: {
    items: {
      type: Array,
      required: true
    }
  },
  computed: {
    subsidiaries() {
      return get(this.$store, 'state.subsidiaries')
    }
  }
}
</script>

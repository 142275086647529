import Vue from "vue";
import store from "../store";
import each from "lodash/each";
import $ from "jquery";

import filters from "./filters/*.js";
each(filters, ({ default: filter }, name) => Vue.filter(name, filter));

import components from "./components/*.vue";
each(components, ({ default: component }, name) => {
  Vue.component(name, component);
});

import VueVirtualScroller from "vue-virtual-scroller";
Vue.use(VueVirtualScroller);

Vue.directive('visible', function (el, binding) {
  el.style.visibility = !!binding.value ? 'visible' : 'hidden';
});

// v-selectpicker
Vue.directive("selectpicker", {
  inserted(el) {
    $(el).selectpicker({
      width: "css-width",
      container: "body",
      noneSelectedText: 'Bitte auswählen...'
    });
    $(el).addClass('v-selectpicker');
    $(el).val(el.value);
    $(el).selectpicker("refresh");
  },
  componentUpdated(el) {
    $(el).val(el.value);
    $('.select-picker').selectpicker("refresh");
  },
  unbind(el) {
    $(el).selectpicker("destroy");
  },
});

Vue.directive("radioboxesFirstIsDefault", {
  update(el) {
    if ($(el).find('input[type="radio"]:checked').length === 0) {
      $(el)
        .find('input[type="radio"]')
        .first()
        .prop("checked", true);
    }
  },
});


const resetPaymentType = function(el) {
  $(el).closest('form').find('#payment-type-2').prop("checked", true);
}

Vue.directive("onchangeResetPaymentType", {
  inserted(el) {
    $(el).on('click blur', function() {
      setTimeout(function() {
        resetPaymentType(el);
      }, 10);
    })
  }
})


import views from "./views/*.js";
$.fn.enableVue = function() {
  each(views, ({ default: createView }, name) => {
    this.find(".js-" + name).each(function() {
      const container = $(this);
      const defaultVueProps = {
        el: this,
        computed: {
          subsidiaries() {
            return this.$store.state.subsidiaries;
          },
          cart() {
            return this.$store.state.cart;
          },
          user() {
            return this.$store.state.user;
          },
        },
        store,
        methods: {
          mp_confirm,
        },
      };

      createView(
        container,
        (vueProps) =>
          new Vue({
            ...defaultVueProps,
            ...vueProps,
            computed: {
              ...defaultVueProps.computed,
              ...vueProps.computed,
            },
            methods: {
              ...defaultVueProps.methods,
              ...vueProps.methods,
            },
          })
      );
    });
  });
  return this;
};

$(() => {
  $("body").enableVue();
});

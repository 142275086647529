import $ from "jquery";
import get from "lodash/get";
import { getSearchParams } from "../../utils";

// Products.js - Normal product page (/artikel/muenzen etc.)

// For computeLastFilterChanged
const oldFilters = {};
export function computeLastFilterChanged(newFilter, oldFilter) {
  let different = [];
  for (const key in newFilter) {
    if (newFilter[key] != oldFilter[key]) {
      different.push(key);
    }
  }

  Object.assign(oldFilter, newFilter);
  return different;
}

const baseLocation = window.location.href.split("?")[0];

const createSparseArray = (arr, length) => {
  return arr.concat(
    Array.apply(0, {
      length: Math.max(0, length - arr.length),
    }).map((x, i) => ({ permalink: i }))
  );
}

const getInitialFilters = () => {
  // These filters can be set:
  const propertyNames = ["serie", "country", "material", "weight", "type", "s", "sortBy", "sortDirection", "showAvailableOnly" /* , "subsidiaryid" if it should stay in the URL, we also need to make sure it gets updated on subsidiary change */];

  const currentSearchParams = getSearchParams();
  let initialFilters = {};
  propertyNames.forEach((name) => {
    initialFilters[name] = (currentSearchParams[name] || "").trim();
  });

  return initialFilters;
}

export default async (container, createVue) => {
  const innerHTML = container.get(0).innerHTML || "";

  const initialFilters = getInitialFilters();

  const getPage = async (args, page = 1) => {
    return (
      await (
        await fetch(
          $(container).data("endpoint") +
            "?" +
            $.param({
              ...args,
              page,
            }),
          { 
            method: "POST",
            body: new URLSearchParams({
              ids: $(container).data('ids') || ''
            }),
            cache: "no-store" 
          }
        )
      ).json()
    ).data;
  };

  createVue({
    name: "Produkte",
    data() {
      return {
        filterOptions: false,
        filters: {},
        pagesLoaded: [],
        items: [],
        pageSize: 50,
        loading: true,
        sortBy: initialFilters.sortBy || container.find('.js-sortBy').data('default'),
        sortDirection: initialFilters.sortDirection || 'asc',
        showAvailableOnly: initialFilters.showAvailableOnly,
      };
    },
    computed: {
      subsidiaryId() {
        return get(this.$store, "state.subsidiaries.selected.orderTypeID");
      },
      filterArgs() {
        return {
          ...this.filters,
          showAvailableOnly: this.showAvailableOnly
            ? this.subsidiaryId || true
            : false,
          sortBy: this.filters.sortBy || container.find('.js-sortBy').data('default'),
        };
      },
    },
    mounted() {
      this.filters = { ...initialFilters };
    },
    methods: {
      updateFilters(filters) {
        this.filters = filters;
      },
      update(startIndex, endIndex) {
        let start = Math.floor(startIndex / this.pageSize) * this.pageSize;
        while (start < endIndex) {
          const page = start / this.pageSize;
          if (!this.pagesLoaded[page]) {
            this.loadPage(page);
          }
          start += this.pageSize;
        }
      },
      async loadPage(page) {
        this.pagesLoaded[page] = true;

        try {
          const filters = $.param(this.filters);
          const data = await getPage(this.filterArgs, page + 1);
          if ($.param(this.filters) !== filters) return;
          const offset = page * this.pageSize;
          const nextItems = [...this.items];
          for (let i = 0; i < data.items.length; i++) {
            nextItems[i + offset] = data.items[i];
          }
          this.items = nextItems;
        } catch {
          this.pagesLoaded[page] = false;
        }
      },
    },
    watch: {
      sortBy: { handler(newValue) { this.filters.sortBy = newValue; } },
      sortDirection: { handler(newValue) { this.filters.sortDirection = newValue; } },
      showAvailableOnly: { handler(newValue) { this.filters.showAvailableOnly = newValue; } },
      filters: {
        handler(newFilters) {
          // Update URL when Filters have changed

          const lastFilterChanged = computeLastFilterChanged(newFilters, oldFilters);
          if (!newFilters.country && lastFilterChanged.includes('country')) {
            newFilters.serie = "";
          }

          let newSearchParams = [];
          for (let key in newFilters) {
            if (newFilters[key]) {
              newSearchParams.push(key + "=" + newFilters[key]);
            }
          }
          let newUrl =
            baseLocation + (newSearchParams.length ? "?" : "") + newSearchParams.join("&");
          if (newUrl != window.location.href) {
            history.replaceState(null, null, newUrl);
          }
        },
        deep: true,
      },
      filterArgs: {
        handler(args) {
          // AJAX load the new page content
          this.pagesLoaded = [];
          this.items = [];

          this.loading = true;
          getPage(args).then((data) => {
            this.filterOptions = data.filterOptions;
            this.pagesLoaded = [true];
            this.items = createSparseArray(data.items, data.total);
            this.pageSize = data.pageSize;
            this.loading = false;
          });
        },
        deep: true,
      },
    },
    template: `
      <div>
        ${innerHTML}
        <products-filter v-if="filterOptions" :value="filters" @input="updateFilters" :options="filterOptions" />
        <div v-if="loading" class="text-center py-5 text-muted">
          <div class="loading-animation loading mb-3">
            <span class="lnr lnr-sync"></span>
          </div>
          Die Produkte werden geladen…
        </div>
        <products-list :items="items" @update="update"  />
      </div>
    `,
  });
};
